import { Checkbox, Grid, Text } from "@mantine/core";
import FormInput from "../../../components/dynamic/FormInput";
import { styles } from "../styles";
import { useFormik } from "formik";
import {
  IRegistrationInitialValues,
  RegistrationInitialValues,
} from "./initial-values/RegistrationFormInitialValues";
import { RegistrationValidationSchema } from "./validations/RegistrationValidationSchema";
import LoadingBtn from "../../../components/loading/LoadingBtn";
import { useRegisterMutation } from "../../../api/test-queries/useRegisterMutation";
import { useTestSeriesStore } from "../../../stores/test-series/test-series-store";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../assets/colors/colors";

const RegistrationForm = () => {
  const { isLoading, mutateAsync } = useRegisterMutation();
  const { classes } = styles();
  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);
  const navigate = useNavigate();

  const handleSubmitForm = async (values: IRegistrationInitialValues) => {
    if (!(testSeriesData.rememberTime > 0)) {
      return;
    }

    try {
      values.testId = testSeriesData._id;
      const resData = await mutateAsync(values);
      if (resData.status === "success") {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "success",
          okCallBack: () =>
            navigate("/verify-otp", { state: { email: values.email } }),
        });
      } else {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "error",
        });
      }
    } catch (error: any) {
      customAlert.show({
        message: error.message,
        title: "Registration Error",
        alertFor: "error",
      });
    }
  };

  const formProps = useFormik({
    initialValues: RegistrationInitialValues,
    onSubmit: (values) => handleSubmitForm(values),
    validationSchema: RegistrationValidationSchema,
  });

  return (
    <>
      <h1 className={classes.formHeading}>Participation Form</h1>

      {testSeriesData.registrationsSlot > 0 && (
        <p className={classes.formDescripction}>
          <span className={classes.blodTextRed}> We’ve</span> remaining
          registrations:{" "}
          {testSeriesData.registrationsSlot - testSeriesData.registeredUsers}
        </p>
      )}

      <form method="post" onSubmit={formProps.handleSubmit}>
        <div className={classes.inputCol}>
          <FormInput
            type={"text"}
            formProps={formProps}
            name="name"
            placeholder="Name"
          />
        </div>
        <div className={classes.inputCol}>
          <FormInput
            type={"email"}
            formProps={formProps}
            name="email"
            placeholder="Email"
          />
        </div>
        <div className={classes.inputCol}>
          <FormInput
            type={"text"}
            formProps={formProps}
            name="college"
            placeholder="College"
          />
        </div>
        <div className={classes.inputCol}>
          <FormInput
            type={"text"}
            formProps={formProps}
            name="mobile"
            placeholder="Mobile"
          />
        </div>
        <div className={classes.checkBox}>
          <Grid m={0} align={"center"}>
            <Checkbox
              name="term_and_condition"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              label=""
              color="red"
              radius="xs"
              size="xs"
            />
            <a
              style={{
                color: colors.themePrimary,
                fontSize: 12,
                marginLeft: 10,
              }}
              target={"_blank"}
              rel="noreferrer"
              href={"https://codexcider.com/terms-and-condition"}
            >
              {"Terms & Condition"}
            </a>
            <span
              style={{
                fontSize: 12,
              }}
            >
              &nbsp;/&nbsp;
            </span>
            <a
              style={{ color: colors.themePrimary, fontSize: 12 }}
              target={"_blank"}
              rel="noreferrer"
              href={"https://codexcider.com/return-policy"}
            >
              {"Rrefund Policy"}
            </a>
          </Grid>
          <div>
            {formProps.touched.term_and_condition &&
            formProps.errors.term_and_condition ? (
              <Text size={10} color={"red"}>
                {formProps.errors.term_and_condition}
              </Text>
            ) : null}
          </div>
        </div>

        <LoadingBtn
          disabled={testSeriesData.rememberTime > 0 ? false : true}
          type="submit"
          loading={isLoading}
          title="Submit"
        />
      </form>
    </>
  );
};

export default RegistrationForm;
