export interface IRegistrationInitialValues {
  name: string;
  email: string;
  college: string;
  mobile: string;
  term_and_condition: boolean;
  testId?: string;
}

export const RegistrationInitialValues: IRegistrationInitialValues = {
  name: "",
  email: "",
  college: "",
  mobile: "",
  term_and_condition: false,
};
