import React from "react";
import FormInput from "../../../components/dynamic/FormInput";
import { styles } from "../styles";
import { useFormik } from "formik";

import { VerifyOtpValidationSchema } from "./validations/VerifyOtpValidationSchema";
import {
  IVerifyOtpInitialValues,
  VerifyOtpInitialValues,
} from "./initial-values/VerifyOtpInitialValues";
import { Box } from "@mantine/core";
import LoadingBtn from "../../../components/loading/LoadingBtn";
import { useVerifyRegisterMutation } from "../../../api/test-queries/useVerifyUserMutation";
import { useTestSeriesStore } from "../../../stores/test-series/test-series-store";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyOtpForm = () => {
  const { classes } = styles();

  const { isLoading, mutateAsync } = useVerifyRegisterMutation();
  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);

  const navigate = useNavigate();

  const { state } = useLocation();

  const handleSubmitForm = async (values: IVerifyOtpInitialValues) => {
    try {
      values.testId = testSeriesData._id;
      values.email = state.email;

      const resData = await mutateAsync(values);
      if (resData.status === "success") {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "success",
          okTitle: "Complete Registration",
          okCallBack: () =>
            navigate("/payment", { state: { txnId: resData.data.txnId } }),
        });
      } else {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "error",
        });
      }
    } catch (error: any) {
      customAlert.show({
        message: error.message,
        title: "Registration Error",
        alertFor: "error",
      });
    }
  };

  const formProps = useFormik({
    initialValues: VerifyOtpInitialValues,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
    validationSchema: VerifyOtpValidationSchema,
  });

  return (
    <>
      <h1 className={classes.formHeading}>Verify OTP</h1>
      <p style={{ margin: 0, fontSize: 12, color: "##afafaf" }}>
        {"OTP has been sent on your registered email id"}
      </p>

      <Box my={5}>
        <form method="post" onSubmit={formProps.handleSubmit}>
          <div className={classes.inputCol}>
            <FormInput
              type={"text"}
              formProps={formProps}
              name="otp"
              placeholder="OTP"
            />
          </div>

          <LoadingBtn type="submit" loading={isLoading} title="Submit" />
        </form>
      </Box>
    </>
  );
};

export default VerifyOtpForm;
