import { Modal } from "@mantine/core";
import React, { useState } from "react";
import { Images } from "../../assets/images/Images";
import RichTextView from "../../components/quill/RichTextView";
import { useTestSeriesStore } from "../../stores/test-series/test-series-store";
import FAQ from "./FAQ";
import Navbar from "./Navbar";
import { styles } from "./styles";

const LeftContainer = () => {
  const { classes } = styles();
  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);
  const [readMore, setReadMore] = useState(false);
  const [faq, setFaq] = useState(false);

  return (
    <div className={classes.leftContainer}>
      <div style={{ flex: 1 }}>
        <Navbar />
      </div>
      <div className={classes.textContainer}>
        <h1 className={classes.bannerHeading}>Give Online Exam &</h1>
        <h1 className={classes.bannerBiggerHeading}>Awarded By Prize Money</h1>
        <p className={classes.subjectText}>{testSeriesData.title}</p>
        <p className={classes.bannerDescripction}>
          show your technical skill to us by participating in our online test
          series and get the chance of wining prize money{" "}
          <a
            className={classes.readMore}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setReadMore(true);
            }}
          >
            Read More
          </a>
        </p>
        <p className={classes.registerationFee}>
          Registration Fee: Rs {testSeriesData.price}/-
        </p>
        <button onClick={() => setFaq(true)} className={classes.faqBtn}>
          Check FAQ
        </button>
      </div>
      <div className={classes.leftbannerContainer}>
        <img
          src={Images.homeBanner}
          className={classes.homeBanner}
          alt="Home Banner"
        />
      </div>
      <Modal
        title={testSeriesData.title}
        onClose={() => setReadMore(false)}
        opened={readMore}
      >
        <RichTextView value={testSeriesData.description} />
      </Modal>
      <Modal
        title={"FAQ"}
        size={"lg"}
        onClose={() => setFaq(false)}
        opened={faq}
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        <FAQ />
      </Modal>
    </div>
  );
};

export default React.memo(LeftContainer);
