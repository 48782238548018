import { createStyles } from "@mantine/core";
import { colors } from "../../assets/colors/colors";

export const useStyles = createStyles({
  alertContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100% !important",
    justifyContent: "center",
    textAlign: "center",
  },
  modalPaper: {
    borderRadius: "20px !important",
  },
  alertTitle: {
    fontWeight: 600,
    color: "#000",
    fontSize: "24px !important",
    margin: 0,
  },
  alertMsg: {
    fontWeight: 500,
    color: "#00000099",
    fontSize: "14px !important",
    margin: "10px 0px !important",
    textAlign: "center",
  },
  ThemeBtn: {
    backgroundColor: colors.themePrimary,
    borderRadius: "5px !important",
    color: "#fff !important",
    margin: "0px 25px !important",
    fontSize: "12px !important",
    marginTop: "10px !important",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: colors.themePrimary,
    },
  },
});
