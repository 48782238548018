import { Box } from "@mantine/core";
import { useFormik } from "formik";
import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCheckUserRegistration,
  useResendOtp,
} from "../../../api/test-queries/useRegisterMutation";
import FormInput from "../../../components/dynamic/FormInput";
import LoadingBtn from "../../../components/loading/LoadingBtn";
import { useTestSeriesStore } from "../../../stores/test-series/test-series-store";
import {
  IVerifyRegistration,
  VerifyRegistrationInitialValues,
} from "../forms/initial-values/VerifyRegistration";
import { verifyRegistrationValidationSchema } from "../forms/validations/VerifyRegistrationValidationSchema";
import { styles } from "../styles";

type TRegistrationStatus =
  | "success"
  | "OTP"
  | "payment"
  | "no_registration"
  | "";

interface Props {
  clodeModel: Function;
}
const CheckRegistration = (props: Props) => {
  const { classes } = styles();
  const { clodeModel } = props;

  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);
  const { isLoading, mutateAsync } = useCheckUserRegistration();
  const { mutateAsync: resendOtp } = useResendOtp();
  const navigate = useNavigate();

  const sendForOtpVerification = useCallback(
    async (email: string) => {
      console.log("sendForOtpVerification");
      try {
        await resendOtp({ testId: testSeriesData._id, email: email });
        navigate("/verify-otp", { state: { email: email } });
        clodeModel();
      } catch (error: any) {
        customAlert.show({
          message: error.message,
          title: "OOPS!",
          alertFor: "error",
        });
      }
    },
    [navigate, resendOtp, testSeriesData._id, clodeModel]
  );

  const makePayment = useCallback(
    (txnId: string) => {
      if (txnId) {
        navigate("/payment", { state: { txnId: txnId } });
        clodeModel();
      }
    },
    [navigate, clodeModel]
  );

  const handleRegistration = useCallback(
    (params: {
      res: JsonResponse;
      status: TRegistrationStatus;
      userData: IVerifyRegistration;
      txnId: string;
    }) => {
      console.log("handleRegistration");

      const { res, status, userData, txnId } = params;

      switch (status) {
        case "OTP":
          customAlert.show({
            message: res.msg.toString(),
            title: res.title.toString(),
            alertFor: "error",
            okTitle: "Verify Email",
            cancelTitle: "Cancel",
            okCallBack: () => {
              sendForOtpVerification(userData.email);
            },
          });
          break;
        case "payment":
          customAlert.show({
            message: res.msg.toString(),
            title: res.title.toString(),
            alertFor: "error",
            okTitle: "Make Payment",
            cancelTitle: "Cancel",
            okCallBack: () => {
              makePayment(txnId);
            },
          });
          break;
        case "success":
          customAlert.show({
            message: "Registration Success",
            title: "DONE",
            alertFor: "success",
            okTitle: "CLOSE",
            okCallBack: () => {
              clodeModel();
            },
          });
          break;
        default:
          break;
      }
    },
    [sendForOtpVerification, makePayment, clodeModel]
  );

  const handleSubmitForm = useCallback(
    async (values: IVerifyRegistration) => {
      try {
        values.testId = testSeriesData._id;
        const resData = await mutateAsync(values);
        console.log("handleSubmitForm", resData);
        const { data } = resData;
        if (resData.status === "success") {
          handleRegistration({
            res: resData,
            status: data.status,
            userData: values,
            txnId: data.txnId,
          });
        } else {
          customAlert.show({
            message: resData.msg,
            title: resData.title,
            alertFor: "error",
          });
        }
      } catch (error: any) {
        customAlert.show({
          message: error.message,
          title: "Registration Error",
          alertFor: "error",
        });
      }
    },
    [handleRegistration, mutateAsync, testSeriesData._id]
  );
  const formProps = useFormik({
    initialValues: VerifyRegistrationInitialValues,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
    validationSchema: verifyRegistrationValidationSchema,
  });

  return (
    <React.Fragment>
      <Box my={5}>
        <form method="post" onSubmit={formProps.handleSubmit}>
          <div className={classes.inputCol}>
            <FormInput
              type={"email"}
              formProps={formProps}
              name="email"
              placeholder="Enter your registered email id"
            />
          </div>
          <LoadingBtn
            type="submit"
            loading={isLoading}
            title="Check Registration"
          />
        </form>
      </Box>
    </React.Fragment>
  );
};

export default memo(CheckRegistration);
