import React from "react";
import ReactQuill from "react-quill";
import hljs from "highlight.js";

interface IRichTextView {
  value: string;
}

const modules = {
  syntax: {
    highlight: (text: any) => hljs.highlightAuto(text).value,
  },
  toolbar: [],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const RichTextView: React.FC<IRichTextView> = ({ value }) => {
  return (
    <ReactQuill
      defaultValue={value}
      style={{ width: "100%" }}
      readOnly={true}
      modules={modules}
      theme="bubble"
    />
  );
};

export default RichTextView;
