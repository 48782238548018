import { createStyles } from "@mantine/core";
import { colors } from "../colors/colors";

export const themeStyles = createStyles({
  themeBtn: {
    backgroundColor: colors.themePrimary,
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: colors.white,
    border: "0px",
    padding: "10px 0px",
    borderRadius: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.themePrimary,
    },
    ":disabled": {
      backgroundColor: colors.offWhite,
    },
  },
});
