import { useMutation } from "react-query";
import { ICancelRegistrationInitialValues } from "../../pages/home/forms/initial-values/CancelRegistrationInitialValues";

import Api from "../../services/Api";
import { APIURLS } from "../../services/APIURLS";

const cancelRegistration = async (data: ICancelRegistrationInitialValues) => {
  const res = await Api.post(APIURLS.CANCEL_REGISTRATION, data);
  return res.data;
};

export const useCancelRegisterMutation = () => {
  return useMutation(cancelRegistration);
};
