import { createStyles, Text } from "@mantine/core";
import { FormikProps } from "formik";
import React, { HTMLInputTypeAttribute } from "react";

const Styles = createStyles({
  root: {},
  input: {
    width: "100%",
    padding: "10px 10px",
    fontSize: 14,
    fontWeight: 500,
    border: "1px solid #DADADA",
    borderRadius: "3px",
    outline: "none",
    color: "#AEAEAE",
    "&::placeholder": {
      color: "#AEAEAE",
    },
  },
});

interface InputValue {
  placeholder: string;
  name: string;
  formProps: FormikProps<any>;
  type: HTMLInputTypeAttribute;
}

const FormInput = (props: InputValue) => {
  const { classes } = Styles();
  const { placeholder, formProps, name, type } = props;
  const { values, handleBlur, handleChange, touched } = formProps;
  let errors: any = formProps.errors;

  return (
    <>
      <input
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        className={classes.input}
        type={type}
        placeholder={placeholder}
      />

      {touched[name] && errors[name] ? (
        <Text size={10} color={"red"}>
          {errors[name]}
        </Text>
      ) : null}
    </>
  );
};

export default FormInput;
