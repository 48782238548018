import { useMutation } from "react-query";
import { IVerifyOtpInitialValues } from "../../pages/home/forms/initial-values/VerifyOtpInitialValues";
import Api from "../../services/Api";
import { APIURLS } from "../../services/APIURLS";

const verifyUser = async (data: IVerifyOtpInitialValues) => {
  const res = await Api.post(APIURLS.VERIFY_REGISTER_MAIL, data);
  return res.data;
};

export const useVerifyRegisterMutation = () => {
  return useMutation(verifyUser);
};
