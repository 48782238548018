import { Center, LoadingOverlay } from "@mantine/core";
import React from "react";
import { colors } from "../../assets/colors/colors";

const PageLoading = () => {
  return (
    <Center style={{ width: "100%", minHeight: "100vh", position: "relative" }}>
      <LoadingOverlay
        loaderProps={{
          size: "md",
          color: colors.themePrimary,
          variant: "oval",
        }}
        visible={true}
        overlayBlur={2}
      />
    </Center>
  );
};

export default PageLoading;
