export const APIURLS = {
  GET_ACTIVE_TEST: "test-series/get-active-series",
  REGISTER_USER: "test-series/register-user",
  VERIFY_REGISTER_MAIL: "test-series/verify-registration",
  GET_TRANSACTION: "payment/get-transaction",
  VERIFY_PAYMENT: "payment/verify-payment",
  CHECK_USER_REGISTRATION: "test-series/check-registration",
  RESET_OTP: "test-series/resend-otp",
  CANCEL_REGISTRATION: "test-series/cancel-registration",
  GENERATE_HASH: "payment/generate-hash",
};
