import { Loader } from "@mantine/core";
import React from "react";
import { colors } from "../../assets/colors/colors";
import { themeStyles } from "../../assets/styles/themeStyles";

interface ILoadingBtn {
  title: string;
  loading: boolean;
  icon?: React.ReactNode;
  type: "submit" | "button";
  disabled?: boolean;
}

const LoadingBtn = (props: ILoadingBtn) => {
  const { classes } = themeStyles();

  const { title, loading, type, disabled = false } = props;

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classes.themeBtn}
    >
      {loading ? (
        <Loader variant="bars" size={20} color={colors.themePrimary} />
      ) : (
        title
      )}
    </button>
  );
};

export default LoadingBtn;
