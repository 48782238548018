import { Accordion } from "@mantine/core";
import React from "react";

const FAQ = () => {
  return (
    <Accordion variant="filled">
      <Accordion.Item value="faq4">
        <Accordion.Control>What is Codex Cider Test Series?</Accordion.Control>
        <Accordion.Panel>
          Codex Cider Test Series is an online platform meant for all those
          students who has coding skills and by participating in the exam a
          student can win the reward by their coding knowledge.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq6">
        <Accordion.Control>
          How can I register to Codex Cider Test Series?
        </Accordion.Control>
        <Accordion.Panel>
          To Register in Codex Cider Test Series please follow the below steps:
          <ol>
            <li>
              Go to{" "}
              <a
                href="https://testseries.codexcider.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                https://testseries.codexcider.com/
              </a>{" "}
              website
            </li>
            <li>
              Fillup the participation form and check
              <a
                href="https://codexcider.com/terms-and-condition"
                target={"_blank"}
                rel="noreferrer"
              >
                Terms & Condition
              </a>
              /
              <a
                href="https://codexcider.com/return-policy"
                target={"_blank"}
                rel="noreferrer"
              >
                Refund Policy
              </a>
            </li>
            <li>Click on the Submit button</li>
            <li>
              Enter OTP which will be sent to your mail id then click OK to
              complete the verification
            </li>
            <li>
              Make payment through net banking, UPI, Credit and Debit Card
            </li>
            <li>
              After successful payment Registeration has been done. You can see
              your Registration ID is generated.
            </li>
            <li>
              Your Registration ID, Test Date and link information with other
              details all will be send to you on your registered mail id.
            </li>
          </ol>
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq5">
        <Accordion.Control>How can I check my Registeration?</Accordion.Control>
        <Accordion.Panel>
          Once you Registered yourself for the Codex Cider Test Series, you will
          get an email in which your Registeration ID, test date-time and test
          link everything is mentioned.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq7">
        <Accordion.Control>
          How can I cancelled my Registeration?
        </Accordion.Control>
        <Accordion.Panel>
          If any student make payment and Registered successfully then only
          he/she can be elligible to cancel the Registeration. For that please
          click on{" "}
          <a
            href="https://testseries.codexcider.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            https://testseries.codexcider.com/
          </a>{" "}
          website. Check on top right corner Cancel Registeration option will be
          available their. Click on it and Cancel your Registeration.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq8">
        <Accordion.Control>
          When will be I eligible for Refund?
        </Accordion.Control>
        <Accordion.Panel>
          Any student who will cancelled the Registeration before 48 hours of
          test day will be get full refund after deduction of processing fee
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq1">
        <Accordion.Control>How can I win the award?</Accordion.Control>
        <Accordion.Panel>
          To win award you need to give exam. On the basis of higher marks we
          decide the ranking. If two or more students get the same marks then,
          the student who takes the minimum time to give answers will be short
          listed. Only Top 3 students will get reward to perform well in the
          examination.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq2">
        <Accordion.Control>What will be the award?</Accordion.Control>
        <Accordion.Panel>
          Top 3 students who perform very well in Test Series and get good marks
          will be rewarded by Codex Cider. The Student who secure 1st position
          will be rewarded by Rs 1500. Second ranked student will be rewarded by
          Rs 1200 & Third ranked student will get Rs 1000.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="faq3">
        <Accordion.Control>How many times I can give exam?</Accordion.Control>
        <Accordion.Panel>
          A student can register multiple times, there will be no limits to
          register yourself in the Codex Cider Test Series. Exam will be
          announced on{" "}
          <a
            href="https://testseries.codexcider.com/"
            target={"_blank"}
            rel="noreferrer"
          >
            https://testseries.codexcider.com/
          </a>{" "}
          platform, so do visit here and check is there any upcoming test series
          is available.
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default FAQ;
