import { createStyles } from "@mantine/core";

export const styles = createStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  headerNav: {
    display: "flex",
    alignItems: "center",
    padding: "40px 40px 0px 40px",
    color: "#AEAEAE",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 500,
  },
  contentBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  contentTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: "#333333",
    textAlign: "center",
    margin: "5px 0 0",
  },
  contentMsg: {
    fontSize: 14,
    fontWeight: 400,
    color: "#858585",
    margin: 0,
    textAlign: "center",
  },
  creditText: {
    color: "#CE050B",
    fontSize: 12,
    fontWeight: 500,
    marginTop: 15,
    textDecoration: "underline",
    textTransform: "uppercase",
  },
});
