export const openPayUOrder = (data: any, callBackFn: Function) => {
  const handlers = {
    responseHandler: function (BOLT: any) {
      callBackFn(BOLT.response.txnStatus);
      if (BOLT.response.txnStatus === "SUCCESS") {
        console.log("Your payment has been successful");
      }
      if (BOLT.response.txnStatus === "FAILED") {
        console.log("Payment failed. Please try again.");
      }
      if (BOLT.response.txnStatus === "CANCEL") {
        console.log("Payment failed. Please try again.");
      }
    },
    catchException: function (BOLT: any) {
      console.log("Payment failed. Please try again.");
    },
  };
  window.bolt.launch(data, handlers);
};
