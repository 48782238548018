import axios from "axios";

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
export const setToken = (token: string) => {
  Api.defaults.headers.common["token"] = `${token}`;
  Api.defaults.headers.common["Accept"] = `application/json`;
  Api.defaults.headers.common["Content-Type'"] = `application/json`;
  Api.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
};

export default Api;
