import Api from "./Api";

interface apiParams {
  url: string;
  values:
    | {
        [key: string]: any;
      }
    | HTMLFormElement;
  loading?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessCallback?: (data: JsonResponse) => void;
  onError?: (data: JsonResponse) => void;
  formData?: boolean;
}

const ApiRequest = async (action: apiParams) => {
  const { onSuccessCallback, loading, onError, formData = false } = action;
  loading && loading(true);
  try {
    let response: any;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (formData) {
      response = await Api.post(action.url, action.values, config);
    } else {
      response = await Api.post(action.url, action.values);
    }

    const data = response.data;
    if (data.status === "success") {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    } else {
      onError ? onError(data) : alert(data.msg);
    }
  } catch (error: any) {
    onError ? onError(error.message) : alert(error.message);
  } finally {
    loading && loading(false);
  }
};

export default ApiRequest;
