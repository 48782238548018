import moment from "moment";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTestSeriesStore } from "../../stores/test-series/test-series-store";
import CancelRegistrationForm from "./forms/CancelRegistrationForm";

import RegistrationForm from "./forms/RegistrationForm";
import VerifyOtpForm from "./forms/VerifyOtpForm";

import { styles } from "./styles";

const RightContainer = () => {
  const { classes } = styles();
  const { pathname } = useLocation();
  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);

  const activeForm = React.useMemo(() => {
    switch (pathname) {
      case "/":
        return <RegistrationForm />;
      case "/verify-otp":
        return <VerifyOtpForm />;
      case "/cancel-registration":
        return <CancelRegistrationForm />;
      default:
        return <RegistrationForm />;
    }
  }, [pathname]);

  return (
    <>
      <div className={classes.rightContainer}>
        <div className={classes.cancelBtnSection}>
          {pathname === "/" ? (
            <Link className={classes.cancelBtn} to="/cancel-registration">
              Cancel Registration
            </Link>
          ) : (
            pathname === "/cancel-registration" && (
              <Link replace={true} className={classes.cancelBtn} to="/">
                Home
              </Link>
            )
          )}
        </div>
        <div className={classes.formSection}>
          {activeForm}
          {testSeriesData.startDate && (
            <div>
              <p className={classes.RegisterationEndData}>
                Registration Ends :{" "}
                {moment(testSeriesData.startDate)
                  .hours(11)
                  .minutes(60)
                  .format("lll")}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RightContainer;
