import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home";
import Payment from "../pages/payment/Payment";
import PayUPayment from "../pages/payment/payuPayment/PayUPayment";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/verify-otp",
    element: <Home />,
  },
  {
    path: "/cancel-registration",
    element: <Home />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/payu-payment",
    element: <PayUPayment />,
  },
]);
