import { useCallback, useEffect, useState, useMemo, memo } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import ApiRequest from "./../../services/ApiRequest";
import { APIURLS } from "./../../services/APIURLS";
import { openPayUOrder } from "./payuPayment/PayUPaymentHelper";
import { openRazorPayOrder } from "./razorPay/RazorPayPayment";
import PaymentError from "./status/PaymentError";
import PaymentLoading from "./status/PaymentLoading";
import PaymentSuccess from "./status/PaymentSuccess";

const Payment = () => {
  const Razorpay = useRazorpay();
  const { state } = useLocation();
  const [paymentStatus, setPaymentStatus] = useState<TPaymentStatus>("loading");
  const navigate = useNavigate();
  const [response, setResponse] = useState<JsonResponse>({
    statusCode: 200,
    status: "success",
    msg: "",
    title: "",
  });

  const setSuccessMessage = useCallback(() => {
    setResponse({
      statusCode: 200,
      status: "success",
      title: "Congratulations!",
      msg: "Your transaction has been completed successfully",
      data: {
        line1: "Your Registration ID: 1234567890",
      },
    });
  }, [setResponse]);

  const setErrorMessage = useCallback(
    (msg: string) => {
      setPaymentStatus("error");
      setResponse({
        statusCode: 200,
        status: "error",
        title: "OOPS!",
        msg: msg,
        data: {},
      });
    },
    [setResponse]
  );

  const showTransactionStatus = useCallback(() => {
    ApiRequest({
      url: APIURLS.GET_TRANSACTION,
      values: {
        txnId: state.txnId,
      },
      onSuccessCallback: (resData) => {
        const { data } = resData;
        const { paymentData } = data;
        if (paymentData) {
          setPaymentStatus("error");
          setResponse({
            statusCode: 200,
            status: "success",
            title: "OOPS!",
            msg: "Transaction not completed",
            data: {
              line1:
                "If your payment has been deducted then it will be refunded within 7 working days",
            },
          });
        } else {
          setPaymentStatus("success");
          setSuccessMessage();
        }
      },
      onError: (error: any) => {
        console.log("error", error);
        setErrorMessage("Something wrong try again");
      },
    });
  }, [state, setSuccessMessage, setErrorMessage]);

  const handlePayment = useCallback(
    (options: any, paymentFor: TPaymentBy) => {
      if (paymentFor === "razorpay") {
        openRazorPayOrder(options, showTransactionStatus, Razorpay);
      } else {
        openPayUOrder(options, showTransactionStatus);
      }
    },
    [Razorpay, showTransactionStatus]
  );

  const getTransaction = useCallback(() => {
    ApiRequest({
      url: APIURLS.GET_TRANSACTION,
      values: {
        txnId: state.txnId,
      },
      onSuccessCallback: (resData) => {
        const { data } = resData;
        const { paymentData, paymentBy } = data;
        if (paymentData) {
          handlePayment(paymentData, paymentBy);
        } else {
          setPaymentStatus("success");
          setSuccessMessage();
        }
      },
      onError: (error: any) => {
        console.log("error getTransaction", error);
        setErrorMessage("Something wrong try again");
      },
    });
  }, [handlePayment, state, setSuccessMessage, setErrorMessage]);

  useEffect(() => {
    if (state === null) {
      navigate("/");
    } else {
      getTransaction();
    }
  }, [getTransaction, state, navigate]);

  const responsePage = useMemo(() => {
    switch (paymentStatus) {
      case "loading":
        return <PaymentLoading />;
      case "success":
        return <PaymentSuccess response={response} />;
      case "error":
        return <PaymentError response={response} />;
      default:
        return <PaymentLoading />;
    }
  }, [paymentStatus, response]);

  return <div>{responsePage}</div>;
};

export default memo(Payment);
