import { Box } from "@mantine/core";
import React from "react";
import { styles } from "./styles";
import { IconArrowNarrowLeft } from "@tabler/icons";
import { Images } from "../../../assets/images/Images";
import { Link, useNavigate } from "react-router-dom";

const PaymentSuccess = (props: { response: JsonResponse }) => {
  const { classes } = styles();
  const { response } = props;
  const navigate = useNavigate();

  const goToHome = (e: any) => {
    e.preventDefault();
    navigate("/", { replace: true });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerNav}>
        <IconArrowNarrowLeft
          onClick={(e) => goToHome(e)}
          style={{ cursor: "pointer", marginRight: 15 }}
        />
        Home
      </Box>
      <Box className={classes.contentBlock}>
        <Box sx={{ textAlign: "center", width: 380 }}>
          <img src={Images.successIcon} alt="success-transaction" />
          <h4 className={classes.contentTitle}>{response.title}</h4>
          <p className={classes.contentMsg}>{response.msg}</p>
          <Link
            onClick={(e) => goToHome(e)}
            className={classes.creditText}
            to={"/"}
          >
            Codex Cider
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
