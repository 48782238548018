import { Box, Button, Card, Modal } from "@mantine/core";
import { Images } from "../../assets/images/Images";
import { useStyles } from "./styles";

const GlobalAlertModal = (props: AlertModalProps) => {
  const { showAlert, setShowAlert, alertData } = props;
  const {
    title,
    message,
    okTitle,
    okCallBack,
    cancelTitle,
    cancelCallback,
    alertFor,
    showButtons = true,
  } = alertData;

  const handleClose = () => setShowAlert(!showAlert);
  const { classes } = useStyles();

  return (
    <Modal
      styles={{
        modal: {
          borderRadius: 10,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          maxWidth: "100% !important",
        },
      }}
      centered
      transition="fade"
      transitionDuration={600}
      transitionTimingFunction="ease"
      opened={showAlert}
      onClose={handleClose}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Card p={0} styles={classes.alertContainer}>
        <Box sx={{ textAlign: "center" }} my={10}>
          {alertFor === "success" ? (
            <img
              alt="correct"
              width={45}
              height={45}
              src={Images.successIcon}
            />
          ) : (
            <img alt="wrong" width={45} height={45} src={Images.infoIcon} />
          )}
        </Box>

        <h4 style={{ textAlign: "center" }} className={classes.alertTitle}>
          {title}
        </h4>
        <h4 style={{ textAlign: "center" }} className={classes.alertMsg}>
          {message}
        </h4>
        {showButtons && (
          <Box style={{ textAlign: "center" }}>
            {cancelTitle && (
              <Button
                onClick={() => {
                  cancelCallback ? cancelCallback() : handleClose();
                }}
                className={classes.ThemeBtn}
              >
                {cancelTitle ? cancelTitle : "Cancel"}
              </Button>
            )}

            <Button
              onClick={() => {
                okCallBack ? okCallBack() : handleClose();
              }}
              className={classes.ThemeBtn}
            >
              {okTitle ? okTitle : "Ok"}
            </Button>
          </Box>
        )}
      </Card>
    </Modal>
  );
};

export default GlobalAlertModal;
