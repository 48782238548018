import { Loader } from "@mantine/core";
import React from "react";
import { colors } from "../../../assets/colors/colors";
import { styles } from "./styles";

const PaymentLoading = () => {
  const { classes } = styles();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Loader color={colors.themePrimary} size={50} />
      <h4 className={classes.contentTitle}>{"Processing Payment..."}</h4>
      <p className={classes.contentMsg}>Please wait! Do not reload the page</p>
    </div>
  );
};

export default PaymentLoading;
