import React from "react";
import { Box, Button, TextInput } from "@mantine/core";
import ApiRequest from "../../../services/ApiRequest";
import { APIURLS } from "../../../services/APIURLS";

const PayUPayment = () => {
  const [hash, setHash] = React.useState("");

  const paymentData = {
    txnid: "TXN546584",
    amount: 100,
    productinfo: "Test",
    firstname: "Abhay",
    email: "abhay@flipr.ai",
  };

  const paymentHandle = () => {
    ApiRequest({
      url: APIURLS.GENERATE_HASH,
      values: paymentData,
      onSuccessCallback: (res) => {
        console.log(res);
        setHash(res.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <>
      <Box p={45}>
        <form
          method="POST"
          encType="multipart/form-data"
          action="https://secure.payu.in/_payment"
        >
          <TextInput name="key" value={`J5q8TVdY`} label="Key" />

          <TextInput name="txnid" value={paymentData.txnid} label="TXN ID" />
          <TextInput name="amount" value={paymentData.amount} label="Amount" />
          <TextInput
            name="productinfo"
            value={paymentData.productinfo}
            label="productinfo"
          />
          <TextInput
            name="firstname"
            value={paymentData.firstname}
            label="firstname"
          />
          <TextInput name="email" value={paymentData.email} label="email" />
          <TextInput name="phone" value={"7869750693"} label="phone" />
          <TextInput name="lastname" value={"jatav"} label="lastname" />
          <TextInput
            name="surl"
            value={
              "https://asia-south1-codex-cider-dev.cloudfunctions.net/v2Dev/payment/button-response-payu/success"
            }
            label="surl"
          />
          <TextInput
            name="furl"
            value={
              "https://asia-south1-codex-cider-dev.cloudfunctions.net/v2Dev/payment/button-response-payu/failure"
            }
            label="furl"
          />
          <TextInput name="hash" value={hash} label="hash" />
          <TextInput
            name="service_provider"
            value={"payu_paisa"}
            label="service_provider"
          />

          <Button type="submit" sx={{ marginTop: 10 }}>
            Payment
          </Button>
        </form>
        <Button type="submit" sx={{ marginTop: 10 }} onClick={paymentHandle}>
          Generate hash
        </Button>
      </Box>
    </>
  );
};

export default PayUPayment;
