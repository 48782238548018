import React from "react";
import FormInput from "../../../components/dynamic/FormInput";
import { styles } from "../styles";
import { useFormik } from "formik";
import { Box } from "@mantine/core";
import LoadingBtn from "../../../components/loading/LoadingBtn";

import { useTestSeriesStore } from "../../../stores/test-series/test-series-store";

import {
  CancelRegistrationInitialValues,
  ICancelRegistrationInitialValues,
} from "./initial-values/CancelRegistrationInitialValues";
import { CancelRegistrationValidationSchema } from "./validations/CancelRegistrationValidationSchema";
import { useCancelRegisterMutation } from "../../../api/test-queries/useCancelRegisterMutation";

const CancelRegistrationForm = () => {
  const { classes } = styles();

  const { isLoading, mutateAsync } = useCancelRegisterMutation();
  const testSeriesData = useTestSeriesStore((state) => state.testSeriesData);

  const handleSubmitForm = async (values: ICancelRegistrationInitialValues) => {
    try {
      values.testId = testSeriesData._id;

      const resData = await mutateAsync(values);
      if (resData.status === "success") {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "success",
        });
      } else {
        customAlert.show({
          message: resData.msg,
          title: resData.title,
          alertFor: "error",
        });
      }
    } catch (error: any) {
      customAlert.show({
        message: error.message,
        title: "Registration Error",
        alertFor: "error",
      });
    }
  };

  const formProps = useFormik({
    initialValues: CancelRegistrationInitialValues,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
    validationSchema: CancelRegistrationValidationSchema,
  });

  return (
    <>
      <h1 className={classes.formHeading}>Cancel Registration</h1>
      <p style={{ margin: 0, fontSize: 12, color: "##afafaf" }}>
        {"Enter your Registered Mail Id"}
      </p>

      <Box my={5}>
        <form method="post" onSubmit={formProps.handleSubmit}>
          <div className={classes.inputCol}>
            <FormInput
              type={"text"}
              formProps={formProps}
              name="email"
              placeholder="Email"
            />
          </div>

          <LoadingBtn type="submit" loading={isLoading} title="Submit" />
        </form>
      </Box>
    </>
  );
};

export default CancelRegistrationForm;
