import React from "react";
import { styles } from "./styles";
import { Grid } from "@mantine/core";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import PageLoading from "../../components/loading/PageLoading";
import { useActiveTestQuery } from "../../api/test-queries/useActiveTestQuery";
import { useTestSeriesStore } from "../../stores/test-series/test-series-store";

const Home = () => {
  const { classes } = styles();
  const { data, isLoading } = useActiveTestQuery();
  const setTestSeriesData = useTestSeriesStore(
    (state) => state.setTestSeriesData
  );

  React.useEffect(() => {
    if (!isLoading && data) {
      if (!data.data || data.data.rememberTime <= 0) {
        customAlert.show({
          alertFor: "error",
          title: "Registrations are closed now",
          message:
            "Registrations are closed now. Please wait for next test series. if you want notification regarding next test series please click on 'Notify Me'",
          okTitle: "Notify Me",
          okCallBack: () =>
            window.open(
              "https://codexcider.com/notify-me/test-series",
              "_blank"
            ),
        });
      } else {
        setTestSeriesData(data.data);
      }
    }
  }, [isLoading, data, setTestSeriesData]);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Grid className={classes.root}>
      <Grid.Col xs={12} sm={12} lg={7} xl={7} style={{ padding: 0 }}>
        <LeftContainer />
      </Grid.Col>
      <Grid.Col xs={12} sm={12} lg={5} xl={5} style={{ padding: 0 }}>
        <RightContainer />
      </Grid.Col>
    </Grid>
  );
};

export default Home;
