import { useMutation } from "react-query";
import { IRegistrationInitialValues } from "../../pages/home/forms/initial-values/RegistrationFormInitialValues";
import { IVerifyRegistration } from "../../pages/home/forms/initial-values/VerifyRegistration";
import Api from "../../services/Api";
import { APIURLS } from "../../services/APIURLS";

const registerUser = async (data: IRegistrationInitialValues) => {
  const res = await Api.post(APIURLS.REGISTER_USER, data);
  return res.data;
};

export const useRegisterMutation = () => {
  return useMutation(registerUser);
};

const checkUserRegistration = async (data: IVerifyRegistration) => {
  const res = await Api.post(APIURLS.CHECK_USER_REGISTRATION, data);
  return res.data;
};
export const useCheckUserRegistration = () => {
  return useMutation(checkUserRegistration);
};

const resendOtp = async (data: { testId: string; email: string }) => {
  const res = await Api.post(APIURLS.RESET_OTP, data);
  return res.data;
};
export const useResendOtp = () => {
  return useMutation(resendOtp);
};
