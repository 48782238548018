import * as Yup from "yup";

const mobileRegx = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const RegistrationValidationSchema = Yup.object({
  name: Yup.string().required("Field is required"),
  college: Yup.string().required("Field is required"),
  mobile: Yup.string()
    .matches(mobileRegx, "Enter a valid mobile number")
    .required("Field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Field is required"),
  term_and_condition: Yup.boolean().oneOf(
    [true],
    "Kindly select the term and condition to register"
  ),
});
