import { createStyles } from "@mantine/core";
import { colors } from "../../assets/colors/colors";

export const styles = createStyles(() => ({
  root: {
    height: "100vh",
    margin: 0,
  },
  // Left Container Css Start
  leftContainer: {
    backgroundColor: colors.themePrimary,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  leftbannerContainer: {
    flex: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  homeBanner: {
    width: "45%",
    display: "block",
    marginTop: -60,
    "@media (max-width: 480px)": {
      width: "75%",
    },
  },
  textContainer: {
    paddingLeft: "50px",
    width: "80%",
    "@media (max-width: 480px)": {
      width: "100%",
      padding: "25px",
    },
  },
  bannerHeading: {
    color: colors.white,
    fontSize: 30,
    fontWeight: 500,
    marginBottom: "10px",
    "@media (max-width: 480px)": {
      fontSize: 26,
      marginBottom: "0px",
    },
  },
  bannerBiggerHeading: {
    color: colors.white,
    fontSize: 34,
    fontWeight: 600,
    margin: "0px",
    "@media (max-width: 480px)": {
      fontSize: 28,
    },
  },
  subjectText: {
    margin: "0px",
    fontSize: 22,

    fontWeight: 500,
    color: colors.white,
    paddingTop: "25px",
    "@media (max-width: 480px)": {
      paddingTop: "10px",
    },
  },
  bannerDescripction: {
    color: colors.white,

    fontSize: 16,
    fontWeight: 500,
    margin: "20px 0px",
    "@media (max-width: 480px)": {
      fontSize: 16,
    },
  },
  readMore: {
    color: colors.white,
    textDecoration: "underline",
    "@media (max-width: 480px)": {},
  },
  registerationFee: {
    color: colors.white,
    margin: "0px",
    fontSize: 18,
    fontWeight: 700,
    "@media (max-width: 480px)": {
      fontSize: 16,
    },
  },
  faqBtn: {
    backgroundColor: colors.white,
    color: colors.themePrimary,
    border: "none",
    padding: "12px 20px",
    fontSize: 16,
    fontWeight: 700,
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: 25,
    "@media (max-width: 480px)": {
      fontSize: 14,
    },
  },

  // Navbar Css
  navContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    "@media (max-width: 480px)": {
      padding: 10,
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 60,
      height: 60,
      "@media (max-width: 480px)": {
        width: 45,
        height: 45,
      },
    },
    "& p": {
      color: colors.white,
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: 600,
      marginLeft: 10,
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
  },
  navItemBlock: {
    display: "flex",
    alignItems: "center",
    listStyleType: "none",
    color: "#fff",
    fontWeight: 700,
    fontSize: 16,
    "& li": {
      margin: "0px 10px 0px 10px",
    },
    "@media (max-width: 480px)": {
      fontSize: 13,
    },
  },

  // rightSide container
  rightContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cancelBtnSection: {
    textAlign: "end",
    paddingTop: "20px",
    paddingRight: "50px",
    "@media (max-width: 480px)": {
      paddingRight: "20px",
    },
  },
  cancelBtn: {
    color: colors.lightBlack,
    fontSize: 16,
    fontWeight: 700,
  },
  formSection: {
    textAlign: "start",
    padding: "1em 3em 3em",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 480px)": {
      padding: "25px",
    },
  },
  formHeading: {
    fontSize: 26,
    fontWeight: 700,
    color: colors.lightBlack,
    margin: 0,
  },
  formDescripction: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    color: colors.lightGrey,
    paddingTop: 8,
    paddingBottom: 14,
  },
  blodTextRed: {
    fontWeight: 500,
    color: colors.themePrimary,
  },
  inputCol: {
    padding: "10px 0px",
  },
  checkBox: {
    padding: "1em 0em",
    "& .mantine-Checkbox-label": {
      color: colors.themePrimary,
      borderBottom: `1px solid ${colors.themePrimary}`,
      paddingLeft: "0px",
      marginLeft: "12px",
      fontSize: "14px",
      fontWeight: 600,
      paddingBottom: "3px",
    },
  },
  submitBtn: {
    backgroundColor: colors.themePrimary,
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
    color: colors.white,
    border: "0px",
    padding: "10px 0px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  RegisterationEndData: {
    fontSize: 15,
    fontWeight: 600,
    margin: "0px",
    textAlign: "center",
    color: colors.lightBlack,
    paddingTop: "20px",
  },
}));
