import { RazorpayOptions } from "react-razorpay";
import { colors } from "../../../assets/colors/colors";

export const openRazorPayOrder = (
  options: RazorpayOptions & { modal: any },
  callBackFn: Function,
  Razorpay: any
) => {
  options.theme = {
    color: colors.themePrimary,
  };
  options.handler = (successData: any) => {
    callBackFn();
  };
  options.modal = {
    ondismiss: () => callBackFn(),
  };

  const rzPay = new Razorpay(options);
  rzPay.open();
  rzPay.on(
    "payment.failed",
    function (response: { error: TPaymentResponseErrorParams }) {
      callBackFn();
    }
  );
};
