import create from "zustand";

export const useTestSeriesStore = create<TTestSeriesStore>((set) => ({
  testSeriesData: {
    _id: "",
    description: "",
    price: "0",
    registrationsSlot: 0,
    startDate: undefined,
    title: "",
    registeredUsers: 0,
    rememberTime: 0,
  },
  setTestSeriesData: (data) =>
    set({
      testSeriesData: data,
    }),
}));
