import logoRed from "./codex-cider-logo-red.png";
import logoWhite from "./codex-cider-white-logo.png";
import homeBanner from "./homeBanner.png";
import correctIcon from "./correct.png";
import wrongIcon from "./wrong.png";
import successIcon from "./success.png";
import infoIcon from "./info.png";
import cancelIcon from "./cancel.png";

export const Images = {
  logoRed: logoRed,
  logoWhite: logoWhite,
  homeBanner: homeBanner,
  correctIcon: correctIcon,
  wrongIcon: wrongIcon,
  successIcon: successIcon,
  infoIcon: infoIcon,
  cancelIcon: cancelIcon,
};
