import { useQuery } from "react-query";
import Api from "../../services/Api";
import { APIURLS } from "../../services/APIURLS";

export const useActiveTestQuery = () =>
  useQuery<JsonResponse>({
    queryKey: ["test-series", "get-active-series"],
    queryFn: async () => {
      try {
        const response: JsonResponse = await Api.post(APIURLS.GET_ACTIVE_TEST);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  });
