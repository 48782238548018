import { Modal } from "@mantine/core";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../assets/images/Images";
import { goToPageOutside } from "../../helper/globalHelper";
import CheckRegistration from "./checkRegistration/CheckRegistration";
import { styles } from "./styles";

const Navbar = () => {
  const { classes } = styles();
  const [checkRegistration, setCheckRegistration] = useState(false);
  const navigate = useNavigate();

  const gotoHome = (path: string) => {
    navigate(path);
  };

  return (
    <div className={classes.navContainer}>
      <div
        data-hover
        onClick={() => gotoHome("/")}
        className={classes.logoContainer}
      >
        <img src={Images.logoWhite} alt="white logo" />
        <p>
          Codex Cider
          <br />
          Test Series
        </p>
      </div>
      <ul className={classes.navItemBlock}>
        <li
          data-hover
          onClick={() =>
            goToPageOutside("https://codexcider.com/about-us", "_blank")
          }
        >
          {"About"}
        </li>
        <li
          data-hover
          onClick={() =>
            goToPageOutside("https://codexcider.com/contact-us", "_blank")
          }
        >
          {"Contact"}
        </li>
        <li data-hover onClick={() => setCheckRegistration(true)}>
          Check Registration
        </li>
      </ul>
      <Modal
        centered
        onClose={() => setCheckRegistration(false)}
        title={"Check Your Registration"}
        opened={checkRegistration}
        overlayOpacity={0.8}
        overlayBlur={8}
      >
        <CheckRegistration clodeModel={() => setCheckRegistration(false)} />
      </Modal>
    </div>
  );
};

export default memo(Navbar);
